@font-face {
    font-family: 'Inter UI';
    font-style: normal;
    font-weight: 400;
    src: local('Inter UI'),
    url('./resources/fonts/Inter-UI-Regular.woff2') format('woff2'),
    url('./resources/fonts/Inter-UI-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Inter UI';
    font-style: italic;
    font-weight: 400;
    src: url('./resources/fonts/Inter-UI-Italic.woff2') format('woff2'),
    url('./resources/fonts/Inter-UI-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Inter UI';
    font-style: normal;
    font-weight: 500;
    src: url('./resources/fonts/Inter-UI-Medium.woff2') format('woff2'),
    url('./resources/fonts/Inter-UI-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Inter UI';
    font-style: italic;
    font-weight: 500;
    src: url('./resources/fonts/Inter-UI-MediumItalic.woff2') format('woff2'),
    url('./resources/fonts/Inter-UI-MediumItalic.woff') format('woff');
}

@font-face {
    font-family: 'Inter UI';
    font-style: normal;
    font-weight: 700;
    src: url('./resources/fonts/Inter-UI-Bold.woff2') format('woff2'),
    url('./resources/fonts/Inter-UI-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Inter UI';
    font-style: italic;
    font-weight: 700;
    src: url('./resources/fonts/Inter-UI-BoldItalic.woff2') format('woff2'),
    url('./resources/fonts/Inter-UI-BoldItalic.woff') format('woff');
}

body {
    font-family: 'Inter UI', sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ajax-link {
  cursor: pointer;
}

#root {
  height: 100%;
}

.react-datepicker__aria-live {
    display: none;
}
