.btn-flat.fic-text-button {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
}

.btn-flat.fic-text-button.small {
  border-radius: 4px;
  padding: 3px 6px;
}

.btn-flat.fic-text-button.medium {
  border-radius: 4px;
  padding: 5px 9px;
}

.btn-flat.fic-text-button.large {
  border-radius: 4px;
  padding: 7px 14px;
  height: 14px;
  max-height: 14px;
}

.btn-disabled {
  opacity: 0.6;
  pointer-events: none;
}
